import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { DataGrid } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import "./AutoQuality.css";

export default function AgentQualityData({
  pieData,
  barData,
  tableData,
  summaryData,
  loading,
  error,
}) {
  const pieChartData = {
    labels: pieData ? Object.keys(pieData) : "",
    datasets: [
      {
        data: pieData ? Object.values(pieData) : "",
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        borderColor: "white",
        borderWidth: 1,
      },
    ],
  };

  const barChartData = {
    labels: barData?.map((item) => item?.call_date),
    datasets: [
      {
        label: "Highest",
        data: barData?.map((item) => item?.highest),
        backgroundColor: "#4BC0C0",
      },
      {
        label: "Lowest",
        data: barData?.map((item) => item?.lowest),
        backgroundColor: "#FF6384",
      },
    ],
  };

  const columns = [
    { field: "id", headerName: "S.No", width: 70 },
    {
      field: "agentname",
      headerName: "Agent Name",
      width: 180,
      filterable: true,
    },
    { field: "campname", headerName: "Campaign Name", width: 220 },
    { field: "final_score", headerName: "Score", width: 100, filterable: true },
    { field: "call_date", headerName: "Call Date", width: 150 },
  ];

  if (loading) {
    return (
      <div className="row mt-2">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div className="card pt-3 pr-1 pl-1 pb-1 w-100">
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="circle" width="100%" height={272} />
          </div>
        </div>

        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <div className="card p-3 w-100">
            <Skeleton variant="rectangular" width="100%" height={300} />
          </div>
        </div>

        <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9 mt-4">
          <div className="card p-3">
            <Skeleton variant="text" width="40%" height={30} />
            <Skeleton variant="rectangular" width="100%" height={180} />
          </div>
        </div>

        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-4">
          <div className="card mb-3 score-card score-card-b-1">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>
          <div className="card mb-3 score-card score-card-b-2">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>
          <div className="card score-card score-card-b-3">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="row mt-2">
      <div className="row p-0 m-0 d-flex align-items-stretch">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex">
          <div className="card pt-3 pr-1 pl-1 pb-1 w-100">
            <h6 className="pl-2">Agents Score</h6>
            {pieData && Object.keys(pieData).length > 0 ? (
              <Pie data={pieChartData} />
            ) : (
              <div className="no-data-placeholder">
                <p>No data available to display.</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex">
          <div className="card p-3 w-100">
            <h6>Highest & Lowest Scores</h6>
            <Bar data={barChartData} />
          </div>
        </div>
      </div>

      <div className="row p-0 mt-2 mr-0 ml-0 mb-1">
        <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9 mt-4">
          <div className="card p-3">
            <h6>Agent Scores Table</h6>
            <DataGrid
              getRowId={(row) => row.session_id}
              rows={tableData}
              columns={columns}
              pageSize={5}
              rowHeight={40}
              rowsPerPageOptions={[5]}
              autoHeight
              disableSelectionOnClick
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-4">
          <div className="card mb-3 score-card score-card-b-1">
            <div className="card-body-score">
              <h6 className="card-title-score">Average Score</h6>
              <h4 className="card-value-score">
                {summaryData?.average !== null ? summaryData?.average : "-"}
              </h4>
            </div>
          </div>
          <div className="card mb-3 score-card score-card-b-2">
            <div className="card-body-score">
              <h6 className="card-title-score">Highest Score</h6>
              <h4 className="card-value-score">
                {summaryData?.highest !== null ? summaryData?.highest : "-"}
              </h4>
            </div>
          </div>
          <div className="card score-card score-card-b-3">
            <div className="card-body-score">
              <h6 className="card-title-score">Lowest Score</h6>
              <h4 className="card-value-score">
                {summaryData?.lowest !== null ? summaryData?.lowest : "-"}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
