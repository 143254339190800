import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DaynmicApicall from "../../../utils/function";
import { Form, Formik } from "formik";
import { TextField } from "../../../components/formValidation/TextField";
import { MultiSelect } from "../../../components/formValidation/MultiSelect";
import PageHeader from "../../../components/PageHeader";
import * as Yup from "yup";
import AgentQualityData from "./AutoQualityData";

export default function AutoQualityDashboard(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [agentoptions, setAgentOptions] = useState([]);
  const [campList, setCampList] = useState([]);
  const [search, setSearch] = useState(false);
  const [pieData, setPieData] = useState({});
  const [barData, setBarData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [summaryData, setSummaryData] = useState({
    average: 0,
    highest: 0,
    lowest: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      await getCampaign();
    };
    fetchCampaigns();
  }, []);

  async function getCampaign() {
    await DaynmicApicall(
      `appuser/getcamp/ALL`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        let uniqueCamp = [
          ...new Map(res?.map((item) => [item["campname"], item])).values(),
        ];
        let camp = uniqueCamp.map((item) => ({
          value: item.campname,
          label: item.campname,
          ...item,
        }));
        setCampList(camp);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  async function getAgentData(camp) {
    await DaynmicApicall(
      `mcrmdlr/getAgentId/${camp}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        const Data = res.map((item) => {
          return {
            value: item.userid,
            label: item.username,
          };
        });
        setAgentOptions(Data);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }

  const calculateMaxDate = (selectedDate, days) => {
    const maxDate = new Date(selectedDate);
    maxDate.setDate(maxDate.getDate() + days);
    return maxDate.toISOString().split("T")[0];
  };

  const validate = Yup.object({
    camp_id: Yup.object().required("Campaign Name is required"),
    date_from: Yup.string().required("Call Date From is required"),
  });

  const onSearch = async (values, { resetForm }) => {
    setSearch(true);

    try {
      setLoading(true);
      const payload = {
        camp_id: values.camp_id.campid,
        call_date_from: values.date_from,
        call_date_to: values.date_to,
        agent_id: values.agent_id ? values?.agent_id?.value : "",
      };
      const response = await DaynmicApicall(
        "mcrmdlr/agentscoredata",
        payload,
        "post",
        userInfo.data[0].UserToken
      );
      const pieDataResponse = response?.data?.agentScorePieChart;
      const barDataResponse = response?.data?.barGraphData;
      const tableData = response?.data?.listData;
      const summaryDataResponse = response?.data?.statistics;
      const tableDataWithId = tableData?.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setPieData(pieDataResponse);
      setBarData(barDataResponse);
      setTableData(tableDataWithId);
      setSummaryData(summaryDataResponse);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader heading="Auto Quality Dashboard" />
      <Formik
        initialValues={{
          camp_id: "",
          date_from: "",
          date_to: "NA",
          agent_id: "",
        }}
        validationSchema={validate}
        onSubmit={onSearch}
      >
        {(formik) => (
          <Form>
            <div className="card pl-2 pr-2 mb-3">
              <div className="row mt-3">
                <MultiSelect
                  label="Camp Name"
                  value={formik?.values?.camp_id}
                  name="camp_id"
                  isMulti={false}
                  format={true}
                  formik={formik}
                  options={campList}
                  onChange={async (value) => {
                    formik.setFieldValue("camp_id", value);
                    await getAgentData(value.campid);
                  }}
                />
                <TextField
                  title="Call Date From"
                  label="Call Date From"
                  type="date"
                  name="date_from"
                />
                <TextField
                  title="Call Date To"
                  label="Call Date To"
                  type="date"
                  name="date_to"
                  max={
                    formik.values["date_from"] !== ""
                      ? calculateMaxDate(formik.values["date_from"], 7)
                      : ""
                  }
                  min={
                    formik.values["date_from"] !== ""
                      ? formik.values["date_from"]
                      : ""
                  }
                  disabled={formik.values["date_from"] === ""}
                />
                <MultiSelect
                  label="Agent Name"
                  value={formik.values.agent_id}
                  name="agent_id"
                  isMulti={false}
                  formik={formik}
                  options={agentoptions}
                  onChange={(value) => formik.setFieldValue("agent_id", value)}
                />
              </div>
              <div className="d-flex justify-content-end w-100">
                <button type="submit" className="btn btn-primary btn-sm m-2">
                  Submit
                </button>
                <button
                  type="reset"
                  className="btn btn-primary btn-sm m-2"
                  onClick={() => {
                    setSearch(false);
                    setAgentOptions([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div>
              {search && (
                <AgentQualityData
                  pieData={pieData}
                  barData={barData}
                  tableData={tableData}
                  summaryData={summaryData}
                  loading={loading}
                  error={error}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
