/*
'-------------------------------------------------------------------------------------------------------------------- 
'Module Name		  :  Dialer CRM Dashboard |   Purpose          :  Manage Every functionlty on CRM AND Dialer|    
'Created By		    :  Harshit Dubey        |   Change Request No:                                  |
'Creation Date 	  :  29-Jul-2022		      |   Description      :                                  |
'Changed By		    :  Harshit Dubey	      |   Change Date 	   :  15-SEP-2022                     |
'Revision History	:  No Change            |                                                       |
'Name				      :  Harshit Dubey        |   Date             :  15-AUG-2022                     |                  
'----------------------------------------------------------------------------------------------------------------------			
'---------------------------------- */

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardNav from "../../components/DashboardNav";
import SideBar from "./DashboardSidebar";
import DashboardHome from "./DashboardHome";
import DashboardFooter from "./DashboardFooter";
import EditeUser from "../ManageUser/EditeUser";
import EditUI from "../ManageUIComponent/EditUI";
import DashboardAreaNav from "./DashboardAreaNav";
import Resetpassword from "../auth/ResetPassword";
import AddMenu from "../ManageMenuMaster/AddMenu";
import ChangePassword from "../auth/ChangePassword";
import AddEditRes from "../ManageResponce/AddEditRes";
import EditScripts from "../ManageScripts/EditScripts";
import { setCampaignDetail } from "../../redux/Campaign";
import EditGlobal from "../ManageGlobaldailer/EditGlobal";
import AddParameter from "../ManageParameter/AddParameter";
import ManageScripts from "../ManageScripts/ManageScripts";
import EditCampaigns from "../ManageCampaigns/EditCampaigns";
import ManageUserMaster from "../ManageUser/ManageUserMaster";
import ManegeCampaigns from "../ManageCampaigns/ManageCampaigns";
import ManageParameter from "../ManageParameter/ManageParameter";
import CrmHomePage from "../../components/CrmServices/CrmHomePage";
import CampaignGroupMenuMap from "../Mapping/CampaignsGroupMenuMap";
import FileUpload from "../../components/formValidation/FileUpload";
import ManageMenuMaster from "../ManageMenuMaster/ManageMenuMaster";
import ManageResponceMaster from "../ManageResponce/ManageResponceMaster";
import MasterGlobalDailer from "../ManageGlobaldailer/MasterGlobalDailer";
import MapComanyBranchDepartment from "../Mapping/MapComanyBranchDepartment";
import ManageUIComponentMaster from "../ManageUIComponent/ManageUIComponentMaster";
import MasterDialerQueue from "../ManageDialerQueue/MasterDailerQueue";
import DaynmicApicall from "../../utils/function";
import { setComponentDetail } from "../../redux/Campaign";
import toast from "react-hot-toast";
import AddMenuqueue from "../ManageDialerQueue/AddMenuqueue";
import DataUpload from "../DataUpload/DataUpload";
import ManageResponseCamp from "../ManageResponseCamp/ManageResponseCamp";
import ManageBotDialerData from "../BotAndDilaerData/ManageBotDialerData";
import socket from "../../utils/socket";
import api from "../../utils/api";
import Dialer from "../../components/Dialer/Dialer";
import "./App.css";
import Draggable from "react-draggable";
import DialerDashboard from "./Dialer/DialerDashboard";
import DataAllocation from "../DataAllocation/DataAllocation";
import ManageBotScript from "../ManageBotScriptMaster/ManageBotScript";
import BotScript from "../ManageBotScriptMaster/BotScript";
import HolidayCalender from "../ManageHolidayCalender/HolidayCalender";
import AddEditHoliday from "../ManageHolidayCalender/AddEdittHoliday";
import AddBotParam from "../ManageBotParam/BotParam";
import ManageBotParam from "../ManageBotParam/ManageBotParam";
import { setdialerInfo } from "../../redux/Dialer";
import ManageCallRecording from "../ManageCallRecording/ManageCallRecording";
import Reporting from "../ManageReporting/Reporting";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ManageCustomerData from "../ManageCustomerData/ManageCustomerData";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ManageLeadNotification from "../ManageLeadNotification/ManageLeadNotification";
import FloatingSidebar from "./FloatingSidebar";
import "./FloatingSidebar.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SendIcon from "@mui/icons-material/Send";
import { Tooltip, Badge, Box, IconButton } from "@mui/material";
import FloatingNotificationbar from "./FloatingNotificationbar";
import BulkDataUploadCSV from "../BulkDataUpload/BulkDataUploadCSV";
import Managecomplain from "../ManageDndComplain/Managecomplain";
import { setCrmWebphone } from "../../redux/dialerStates";
import AutoQualityDashboard from "./AutoQuality/AutoQualityDashboard";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [getFormStatus, setFormStatus] = useState(false);
  const { dialerInfo } = useSelector((state) => state?.dialer);
  let { crmsaveandexit } = useSelector((state) => state?.dialer);
  const [update, setUpdate] = useState(false);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const { activeMenu, paremsData } = useSelector((state) => state.campaign);
  const handelUpdate = (data) => setUpdate(data);
  const { page } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [displayCrm, setDiaplayCrm] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState("");
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [getNotification, setNotification] = useState([]);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      setNotification((prevNotifications) => [...prevNotifications, data]);
      toast(
        (t) => (
          <div style={{ display: "flex" }}>
            <span>
              {data.remarks} from {data.fromid}
            </span>
            <button
              onClick={() => toast.dismiss(t.id)}
              style={{
                padding: 0,
                margin: 0,
                background: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <CloseRoundedIcon />
            </button>
          </div>
        ),
        {
          icon: <SendIcon />,
          style: {
            borderRadius: "15px",
            background: "#40b0ee",
            color: "#fff",
          },
          position: "top-right",
          duration: Infinity,
        }
      );
    };

    socket.on("pushnotify", handleSocketEvent);

    return () => {
      socket.off("pushnotify", handleSocketEvent);
    };
  }, []);

  useEffect(() => {
    if (!isOpenSidebar) {
      setIsOpen(false);
      setIsOpen1(false);
    }
  }, [isOpenSidebar]);

  async function getDialerServer() {
    let dlrserver = await f_getConnectedCamp("ALL", userInfo.data[0].userid);
    const queueNames = dlrserver.map((item) => item.queue_name);

    let agentSIPConfig = {
      agent_token: userInfo.data[0]?.dlrAgentId,
      wp_config: {
        auto_answer: "true",
        queue_login_code: "*40",
        queue_logout_code: "*41",
      },
      wp_sip_config: {
        sip_username: userInfo.data[0]?.dlrAgentId,
        wss_server: dlrserver[0]?.wss_server_path, // dialer server websocket address/port
        sip_password: userInfo.data[0]?.sippassword,
        sip_server: dlrserver[0]?.dlr_server_ip,
      },
      wp_queue_list: [],
    };
    socket.emit("crmdialerdata", agentSIPConfig, (data) => {});
  }

  const f_getConnectedCamp = async (campid, userid) => {
    const camp = await api.get(`appuser/getcampbycampid/${campid}/${userid}`, {
      headers: {
        Authorization: userInfo.data[0].UserToken,
      },
    });
    return camp.data.data;
  };

  useEffect(() => {
    const joinRoom = () => {
      socket.emit(
        "join",
        {
          userid: "C" + userInfo.data[0]?.dlrAgentId,
          agent_token: `${userInfo.data[0]?.dlrAgentId}`,
        },
        (error) => {
          if (error) {
            console.log("Error: ", error);
          }
        }
      );
    };

    // Initial join when component mounts
    joinRoom();

    // Event listener for socket connection loss
    socket.on("disconnect", () => {
      joinRoom();
    });

    // Socket event listener for incoming events
    socket.on("respfromdialer", async (data) => {
      switch (data.action) {
        case "get_agent_config":
          getDialerServer();
          break;
        case "call_state":
          if (
            data?.event_data?.crm_data &&
            data?.event_data?.call_status === "answered" &&
            data.event_data.agent_token == userInfo.data[0]?.dlrAgentId
          ) {
            dispatch(setdialerInfo({ dialerinfo: data.event_data }));
            dispatch(
              setCrmWebphone({
                dialerHangupStatus: true,
              })
            );
            setDiaplayCrm(true);
            localStorage.setItem(
              "currentcall",
              JSON.stringify(data.event_data)
            );
          }
          if (
            data.event_data.call_direction === "outgoing" &&
            data?.event_data?.call_status === "answered" &&
            data.event_data.agent_token == userInfo.data[0]?.dlrAgentId
          ) {
            localStorage.setItem(
              "currentListening",
              JSON.stringify(data.event_data)
            );
          }
          if (
            data?.event_data?.crm_data === "" &&
            data.event_data.call_direction === "incoming" &&
            data?.event_data?.call_status === "answered" &&
            data.event_data.agent_token == userInfo.data[0]?.dlrAgentId
          ) {
            localStorage.setItem(
              "currentcallend",
              JSON.stringify(data.event_data)
            );
          }
          break;
        default:
      }
    });

    // Cleanup function to disconnect socket and remove listeners
    return () => {
      socket.disconnect();
      socket.off();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (userInfo.data[0].usergroup !== "AGENT") {
        await setCampdata();
        await setComponent();
        setUpdate(false);
      }
    })();
  }, [update]);

  useEffect(() => {
    if (!dialerInfo.dialerinfo) {
      setDiaplayCrm(false);
    }
  }, [!dialerInfo.dialerinfo]);

  async function setCampdata() {
    await DaynmicApicall(
      `/appuser/campmaster/${userInfo.data[0].userid}`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        dispatch(setCampaignDetail({ data: res }));
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }
  async function setComponent() {
    await DaynmicApicall(
      `/appuser/getcomponentlist/ALL`,
      "",
      "get",
      userInfo.data[0].UserToken
    )
      .then((res) => {
        dispatch(setComponentDetail({ data: res }));
      })
      .catch((error) => {
        toast.error(
          error.response.message ??
            error.message ??
            "OOPs, Something went wrong."
        );
      });
  }
  const pages = {
    MNU_DASHBOART: <DashboardHome activemenu={activeMenu} />,
    MNU_RESET_PWD: <Resetpassword activemenu={activeMenu} />,
    MNU_UPLOAD_FILE: <FileUpload activemenu={activeMenu} />,
    MNU_HOLIDAY_UPD: <DashboardHome activemenu={activeMenu} />,
    MNU_CHANGE_PWD: <ChangePassword activemenu={activeMenu} />,
    MNU_CRUD_MENU: <ManageMenuMaster activemenu={activeMenu} />,
    MNU_MANAGE_CAMP: <ManegeCampaigns activemenu={activeMenu} />,
    MNU_MANAGE_SCRIPTS: <ManageScripts activemenu={activeMenu} />,
    MNU_CREATE_USER: <ManageUserMaster activemenu={activeMenu} />,
    MNU_MANAGE_PARAMETER: <ManageParameter activemenu={activeMenu} />,
    MNU_GLOBAL_DIALER: <MasterGlobalDailer activemenu={activeMenu} />,
    MNU_CBD_MAP: <MapComanyBranchDepartment activemenu={activeMenu} />,
    CAMP_GROUP_MENU_MAP: <CampaignGroupMenuMap activemenu={activeMenu} />,
    MNU_MANAGE_RESPONSE: <ManageResponceMaster activemenu={activeMenu} />,
    MNU_CRUD_UICOMP: <ManageUIComponentMaster activemenu={activeMenu} />,
    MNU_CAMP_RESP_MAP: <ManageResponseCamp activemenu={activeMenu} />,
    MNU_MANAGE_DIALER_Q: <MasterDialerQueue activemenu={activeMenu} />,
    MNU_DATA_UPLOAD: <DataUpload activemenu={activeMenu} />,
    DLR_BOT_DATA: <ManageBotDialerData activemenu={activeMenu} />,
    MNU_DATA_ALLO: <DataAllocation activemenu={activeMenu} />,
    MNU_DIALER_DASH: <DialerDashboard activemenu={activeMenu} />,
    MNU_BOT_SCRIPT: <BotScript activemenu={activeMenu} />,
    MNU_HOLI_CALENDER: <HolidayCalender activemenu={activeMenu} />,
    MNU_BOT_PARAM: <ManageBotParam activemenu={activeMenu} />,
    MNU_CALL_RECORD: <ManageCallRecording activemenu={activeMenu} />,
    MANAGE_REPORTING: <Reporting activemenu={activeMenu} />,
    MNU_CUST_DATA: <ManageCustomerData activemenu={activeMenu} />,
    MNU_LEAD_NOTIFY: <ManageLeadNotification activemenu={activeMenu} />,
    MNU_CRM_BULKDATA_UPLOAD: <BulkDataUploadCSV activemenu={activeMenu} />,
    MNU_DND_COMPLAIN: <Managecomplain activemenu={activeMenu} />,
    MNY_AUTOQUALITY: <AutoQualityDashboard activemenu={activeMenu} />,
  };
  const subpages = {
    manageui: <EditUI data={{ paremsData, activeMenu, handelUpdate }} />,
    addmenu: <AddMenu data={{ paremsData, activeMenu }} />,
    manageuser: <EditeUser data={{ paremsData, activeMenu }} />,
    addresponse: <AddEditRes data={{ paremsData, activeMenu }} />,
    masterglobal: <EditGlobal data={{ paremsData, activeMenu }} />,
    managecamp: <EditCampaigns data={{ paremsData, activeMenu }} />,
    managescripts: <EditScripts data={{ paremsData, activeMenu }} />,
    managatsparameter: <AddParameter data={{ paremsData, activeMenu }} />,
    managedialerq: <AddMenuqueue data={{ paremsData, activeMenu }} />,
    managebotscript: <ManageBotScript data={{ paremsData, activeMenu }} />,
    addeditholiday: <AddEditHoliday data={{ paremsData, activeMenu }} />,
    addbotparam: <AddBotParam data={{ paremsData, activeMenu }} />,
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (param, data) => {
    setSnackbarContent(data);
    setSnackbarOpen(true);
  };

  function openForm(value) {
    document.getElementById("myForm").style.display = value ? "block" : "none";
  }

  const handleToggleSidebar = async () => {
    await setIsOpenSidebar(!isOpenSidebar);
  };

  const togglePopup = () => {
    if (isOpen1) {
      setIsOpen1(!isOpen1);
    }
    setIsOpen(!isOpen);
  };

  const togglePopup1 = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
    setIsOpen1(!isOpen1);
  };
  return (
    <>
      <div className="container-fulid m-0 b-0">
        {/* {userInfo.data[0].usergroup !== "AGENT" ? ( */}
        {userInfo.data[0].usergroup !== "AGENT" && (
          <div style={{ display: displayCrm ? "none" : "" }}>
            <SideBar>
              <DashboardNav />
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <DashboardAreaNav>
                      {subpages[paremsData.url] ? (
                        subpages[paremsData.url]
                      ) : pages?.[page] ? (
                        pages[page]
                      ) : (
                        <DashboardHome
                          handleSnackbarOpen={handleSnackbarOpen}
                          snackbarOpen={snackbarOpen}
                          setSnackbarOpen={setSnackbarOpen}
                          setSnackbarContent={setSnackbarContent}
                        />
                      )}
                    </DashboardAreaNav>
                    {subpages[paremsData.url] ? (
                      subpages[paremsData.url]
                    ) : pages?.[page] ? (
                      pages[page]
                    ) : (
                      <DashboardHome
                        handleSnackbarOpen={handleSnackbarOpen}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                        setSnackbarContent={setSnackbarContent}
                      />
                    )}
                  </div>
                </div>
              </div>
            </SideBar>
            <DashboardFooter />
            {userInfo.data[0].usergroup !== "GUEST" && (
              <div>
                <Draggable>
                  <div id="drag1">
                    <div className="form-popup m-0 b-0" id="myForm">
                      <div className="phone">
                        <section
                          className="phoneView"
                          style={{ overflowY: "scroll", overflowX: "hidden" }}
                        >
                          <Dialer />
                        </section>
                      </div>
                    </div>
                  </div>
                </Draggable>
                <Draggable>
                  <div className="position-fixed bottom-0 end-0 mb-3">
                    <button
                      type="button"
                      className="btn btn-dark btn-circle btn-xl"
                      style={{ height: "60px", width: "60px" }}
                      onClick={(e) => {
                        setFormStatus(!getFormStatus);
                        openForm(getFormStatus);
                      }}
                    >
                      <PhoneAndroidIcon />
                    </button>
                  </div>
                </Draggable>
              </div>
            )}
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
                {snackbarContent}
              </Alert>
            </Snackbar>
          </div>
        )}

        <div
          className="container-fluid overflow-hidden"
          style={{
            display:
              displayCrm || userInfo.data[0].usergroup === "AGENT"
                ? ""
                : "none",
          }}
        >
          <CrmHomePage />
        </div>
      </div>
      {userInfo.data[0].usergroup !== "AGENT" &&
      userInfo.data[0].usergroup !== "GUEST" ? (
        <div
          style={{
            position: "fixed",
            right: 0,
            top: "30%",
            transform: "translateY(-50%)",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: isOpenSidebar ? "50px" : "35px",
              height: "auto",
              backgroundColor: "#10394f",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              transition: "width 0.3s",
              borderTopLeftRadius: isOpen || isOpen1 ? "0px" : "10px",
              borderBottomLeftRadius: isOpen || isOpen1 ? "0px" : "10px",
            }}
          >
            <IconButton
              onClick={handleToggleSidebar}
              sx={{
                color: "white",
                alignSelf: isOpenSidebar ? "flex-end" : "center",
                mb: isOpenSidebar ? 2 : 0,
                alignSelf: "center",
              }}
            >
              {!isOpenSidebar ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
            {isOpenSidebar && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Tooltip
                    title="Dialer/Bot Dashboard"
                    arrow
                    placement="left-start"
                  >
                    <AssessmentIcon
                      onClick={togglePopup}
                      // className="parent-toggle-button"
                      style={{ height: "2rem", width: "2rem" }}
                      sx={{
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Notification" arrow placement="left-start">
                    <div
                    // className="parent-toggle-button2"
                    >
                      <Badge
                        badgeContent={getNotification.length}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "white",
                            backgroundColor:
                              getNotification.length > 0 ? "red" : "grey",
                            border: "2px solid",
                            borderColor:
                              getNotification.length > 0
                                ? "red"
                                : "transparent",
                          },
                        }}
                      >
                        <CircleNotificationsIcon
                          onClick={togglePopup1}
                          style={{ height: "2rem", width: "2rem" }}
                          sx={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </Badge>
                    </div>
                  </Tooltip>
                </Box>

                <FloatingSidebar isOpen={isOpen} togglePopup={togglePopup} />
                <FloatingNotificationbar
                  isOpen={isOpen1}
                  togglePopup={togglePopup1}
                />
              </>
            )}
          </Box>
        </div>
      ) : null}
    </>
  );
}
